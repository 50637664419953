/* eslint-disable no-undef */
import Head from "next/head";

interface SEOProps {
  title?: string;
  path: string;
  description?: string;
}

export const SEO = ({ title, path, description }: SEOProps) => {
  const url = process.env.NEXT_PUBLIC_BASE_URL + path;
  return (
    <Head>
      <title>{`${title ?? ""}`}</title>
      <link rel="canonical" href={url} />
      <meta name="description" content={description ?? ""} />
      <meta property="og:title" content={title ?? ""} />
      <meta property="og:url" content={url ?? ""} />
      <meta property="og:description" content={description ?? ""} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title ?? ""} />
      <meta name="twitter:description" content={description ?? ""} />
    </Head>
  );
};

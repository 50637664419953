import React from "react";
import SwiperCore from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import { Header } from "../modules/consignment";
import { Footer } from "../modules/landing/components/footer";
import { SEO } from "../components/seo/seo";
import { Button } from "../components/ui/button"
import Image from "next/image"
import Link from "next/link";
import FloatingButton from '../components/floating-button/floating-button'
import { track } from "@vercel/analytics/react";

SwiperCore.use([Pagination, Navigation]);

export default function Index() {
  return (
    <main className="flex flex-col justify-between bg-white">
      <SEO title="Jooalan" path="/" description="Mulai cuanmu sekarang" />
      <Header />
      <section className="background-main-style bg-gradient-to-br from-white to-red-100 py-10">

      <div className="max-w-6xl mx-auto text-center px-4 sm:px-6 lg:px-8 background-main-kelas">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-6">
            <div className="relative h-[400px] p-6">
              <Image
                src="/img/common/landingai.svg"
                alt="Ilustrasi tools AI"
                layout="fill"
                objectFit="contain"
              />
            </div>
            <div className="p-6">
              <div className="text-left">
                <div className="inline-block text-left bg-red-100 rounded-lg py-1 px-2 mb-4">
                  <span className="text-maroon font-semibold text-sm">Coming Soon</span>
                </div>
              </div>
              <h1 className="text-3xl font-bold text-gray-900 text-left mb-6">
                Asisten Virtual untuk Mulai Berjualan
              </h1>
              <p className="text-gray-600 text-left mb-12">
                Gabung dalam waitlist kami dan jadi yang pertama mencoba kemudahan berjualan menggunakan asisten virtual Jooalan.
              </p>
              <Link href="https://tally.so/r/nWMPpj" className="flex text-left">
                <button
                  type="submit"
                  onClick={() => {
                    track('click_waitlist');
                  }}
                  className="button-style bg-red-600 text-white px-4 py-2 rounded-r-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                >
                  SAYA TERTARIK MENCOBA
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className="max-w-6xl mx-auto text-center px-4 sm:px-6 lg:px-8 background-main-kelas mt-10 grid lg:grid-cols-3 gap-4 items-center">
          <div className="p-6 text-left lg:col-span-2">
            <h1 className="text-3xl font-italic text-gray-900 mb-4"><i>#KelasCUAN</i></h1>
            <h2 className="text-3xl font-bold text-gray-800 mb-6">
              Buka Toko Anti Ribet
            </h2>
            <p className="text-lg text-gray-600 mb-8">
              Belajar jualan mulai dari NOL tanpa RIBET. Anda akan didampingi mentor yang akan mengajari HINGGA BISA. 
              Dibantu dengan teknologi Artificial Intelligence (AI) yang memudahkan berjualan. 
              Kelas ini cocok diikuti oleh mahasiswa, ibu rumah tangga, atau siapa saja yang ingin mendapatkan penghasilan ekstra lewat berjualan.
            </p>
            <Link href='/kelas' rel="noopener noreferrer">
              <Button onClick={() => {
                  track('click_daftar_landing');
                }} size="lg" className="button-style bg-red-600 hover:bg-red-700 text-white">
                DAFTAR KELAS SEKARANG
              </Button>
            </Link>
          </div>
          <div className="hidden p-2 lg:col-span-1 lg:flex justify-center">
            <div className="relative w-full h-80">
              <Image
                src="/img/common/kelascuan.svg"
                alt="Jooalan icon"
                layout="fill"
                objectFit="contain"
              />
            </div>
          </div>
        </div>

      </section>
      <FloatingButton />
      <Footer />
    </main>
  );
}
